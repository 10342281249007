import logo from './logo3.png';
import './App.css';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDefGHdLchHv7TtyVhgZoXnqOaDRvJ0sR4",
  authDomain: "wellington-75ccc.firebaseapp.com",
  projectId: "wellington-75ccc",
  storageBucket: "wellington-75ccc.appspot.com",
  messagingSenderId: "163661347013",
  appId: "1:163661347013:web:6ff0e221d4271fb89518ac"
};

function App() {
  const app = initializeApp(firebaseConfig);
  return (
    <div className="App" style={{backgroundImage:`url(${logo})`}}>
      {/* <img src={logo} style={{ width: "100%", height: "100vh !important" }} /> */}
      <a href="https://drive.google.com/u/1/uc?id=1t3F5UijIifaaFX5NS-92jVl4q_ZP-vbq&export=download" target="_blank" className="button" >Baixar agora!</a>
    </div>
  );
}

export default App;
